*{
  font-family: system-ui, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

:root {
  --primary-color: #1d3557;
  --secondary-color: #df3946; 
  --third-color: #ffffff;
}


  body {
    margin: 0;
    color: var(--third-color);
  }

  @media (max-width: 600px) {
    html{
      width: auto;
    }
  }


